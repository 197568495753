import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { UserRoleMenuTabNameModel } from '../../models/user-role-menu-tab-name/user-role-menu-tab-name.model';
import { CurrentUserPermissionsService } from '../../services/current-user-permissions/current-user-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionForMenuTabGuard {
  constructor(
    private router: Router,
    private currentUserPermissionsService: CurrentUserPermissionsService
  ) {}

  public canActivate(menuTabName: UserRoleMenuTabNameModel): UrlTree | boolean {
    const hasUserAccess: boolean = this.currentUserPermissionsService.getShowValueForMenuTabName(menuTabName);

    if (hasUserAccess) {
      return true;
    }

    return this.router.createUrlTree(['/']);
  }
}
