import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, Observable, throwError } from 'rxjs';

import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';
import { ApiErrorModel } from '../../models/api-error/api-error.model';
import { ModalService } from '../../../modal/services/modal.service';
import { ApiErrorsUtil } from '../../utils/api-errors/api-errors.util';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';

export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private authUserService: AuthUserService
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return this.handleError(errorResponse);
      })
    );
  }

  private handleError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    if (ApiErrorsUtil.isConnectorError(httpErrorResponse)) {
      return throwError(() => httpErrorResponse);
    }

    const error: ApiErrorModel = ApiErrorsUtil.getErrorFromHttpResponseError(httpErrorResponse);

    if (ApiErrorsUtil.is401Error(httpErrorResponse)) {
      this.authUserService.logoutFromApplication().subscribe(() => {
        this.closeAllModals();
        this.navigateToLoginPage();
      });
    }

    if (ApiErrorsUtil.is403Error(httpErrorResponse) && ApiErrorsUtil.isBlocked(httpErrorResponse)) {
      this.showBlockedUserPopup();
    }

    if (ApiErrorsUtil.is403Error(httpErrorResponse) && ApiErrorsUtil.isForbidden(httpErrorResponse)) {
      this.showForbiddenForUserPopup();
    }

    return throwError(() => error || httpErrorResponse);
  }

  private showBlockedUserPopup(): void {
    const modalService: ModalService = this.injector.get(ModalService);

    modalService
      .openPopupModal('auth.blockerUserPopup.titleKey', 'auth.blockerUserPopup.textKey', 'auth.blockerUserPopup.successButtonLabelKey')
      .subscribe()
      .unsubscribe();
  }

  private showForbiddenForUserPopup(): void {
    const modalService: ModalService = this.injector.get(ModalService);

    modalService
      .openPopupModal(
        'auth.forbiddenForUserPopup.titleKey',
        'auth.forbiddenForUserPopup.textKey',
        'auth.forbiddenForUserPopup.successButtonLabelKey'
      )
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }

        this.navigateToDashboardAndReloadApp();
      });
  }

  private navigateToLoginPage(): void {
    this.router.navigate(['/auth/login'], { state: LocationGetStateUtil.createState(this.getCanDeactivateGuardState()) });
  }

  private navigateToDashboardAndReloadApp(): void {
    this.closeAllModals();

    this.router.navigate(['/']).then(() => window.location.reload());
  }

  private closeAllModals(): void {
    const ngbModal: NgbModal = this.injector.get(NgbModal);

    ngbModal.dismissAll();
  }

  private getCanDeactivateGuardState(): CanDeactivateGuardModel<Record<string, never>> {
    return {
      canDeactivate: true,
    };
  }
}
